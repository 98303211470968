import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { adminSignIn, adminSignout } from "../actions/LoginAction";
import LoadingBox from "../components/LoadingBox";
import MessageBox from "../components/MessageBox";

function LoginScreen() {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const adminsignin = useSelector((state) => state.adminSignin);
    const { adminInfo,error,loading } = adminsignin;
    const navigate=useNavigate()
    const dispatch=useDispatch()
    const submitHandler=(e)=>{
        e.preventDefault()
        dispatch(adminSignIn(email,password))
        // navigate('/gallery')
    }

    useEffect(()=>{
        const inputs = document.querySelectorAll(".input");


        function addcl(){
            let parent = this.parentNode.parentNode;
            parent.classList.add("focus");
        }
        
        function remcl(){
            let parent = this.parentNode.parentNode;
            if(this.value == ""){
                parent.classList.remove("focus");
            }
        }
        
        
        inputs.forEach(input => {
            input.addEventListener("focus", addcl);
            input.addEventListener("blur", remcl);
        });
    },[])
    useEffect(()=>{
      if(adminInfo){
          navigate('/gallery')
       
      }
     },[adminInfo])

     if(error){
      console.log(error,'er');
     }
 
  return (
  
    <div>
      <>
        {/* Hello world */}
        {/* <img className="wave" src="/assets/images//wave.png" /> */}
        <div className="containers">
          <div className="imgs">
            <img src="/assets/images//bg.svg" />
          </div>
          <div className="login-content">
            <form className="forms" onSubmit={submitHandler}>
              <img src="/assets/images//avatar.svg" />
              <h2 className="title">Welcome</h2>
              {loading && <LoadingBox></LoadingBox>}
              {error && <MessageBox variant='danger'>{error}</MessageBox>}
              <div className="input-div one" style={{marginTop:'20px'}}>
                <div className="i">
                  <i className="fas fa-user" />
                </div>
                <div className="div">
                  <h5>Email</h5>
                  <input type="email" className="input" onChange={(e)=>setEmail(e.target.value)}/>
                </div>
              </div>
              <div className="input-div pass">
                <div className="i">
                  <i className="fas fa-lock" />
                </div>
                <div className="div">
                  <h5>Password</h5>
                  <input type="password" className="input" onChange={(e)=>setPassword(e.target.value)}/>
                </div>
              </div>
              {/* <a href="#">Forgot Password?</a> */}
              <input type="submit" className="btn" />
              
            </form>
            {/* {adminInfo && (
               <button onClick={signoutHandler} className="btn" >signout</button>
              )} */}
          </div>
        </div>
      </>
    </div>
  );
}

export default LoginScreen;
