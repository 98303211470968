import React, { useEffect, useState } from "react";
import { Box, GridItem, Grid } from "@chakra-ui/react";
import styles from "../styles/Dashboard.module.css";
import {
    FormControl,
    FormLabel,
    Input,
    FormErrorMessage,
    FormHelperText,
  } from '@chakra-ui/react'
import { Textarea } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addFaq } from "../actions/FaqAction";
import Swal from "sweetalert2";
import AdminNavbar from "../components/AdminNavbar";
import LoadingBox from "../components/LoadingBox";

function AddFaq() {
    const [question, setQestion] = useState('')
    const [answer, setAnswer] = useState('')
    const dispatch=useDispatch()
    const createFaq=useSelector(state=>state.createFaq)
    const {success,loading}=createFaq
    const navigate=useNavigate()

    const submitHandler=(e)=>{
      e.preventDefault()

      dispatch(addFaq(question,answer))
    }

    
  useEffect(()=>{
    if(success){ 
      Swal.fire({
        title: 'FAQ created Succesfully.',
        text: "Thanks",
        type: 'success',    
        icon: 'success',        
      }); 
      navigate('/faq')
    }
  
  },[success])
    // console.log(title);
  return (
    <div>
      <AdminNavbar>
      <Box as="section"  className="faq">
        <Grid
          templateRows="repeat(1, 1fr)"
          templateColumns="repeat(3, 1fr)"
          className={styles.details}
          gap={4}
        >
          <GridItem
            colSpan={{ base: 4, xl: 4 }}
            className={styles.recentOrders}
          >
            <form onSubmit={submitHandler}>
            <FormControl isRequired className="faq-form">
              <FormLabel>Question</FormLabel>
              <Input placeholder="Question" onChange={(e)=>setQestion(e.target.value)} required/>
              <FormLabel>Answer</FormLabel>
              <Textarea  placeholder="Answer" onChange={(e)=>setAnswer(e.target.value)} required/>
              <button type="submit">submit</button>
              {loading && <LoadingBox></LoadingBox>}
            </FormControl>
            </form>
          </GridItem>
        </Grid>
      </Box>
      </AdminNavbar>
    </div>
  );
}

export default AddFaq;
