import { Box, FormControl, FormLabel, Grid, GridItem, Select } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { addBanner } from "../actions/BannerAction";
import AdminNavbar from "../components/AdminNavbar";
import LoadingBox from "../components/LoadingBox";
import styles from "../styles/Dashboard.module.css";

function AddBanner() {

  const [avatar, setAvatar] = useState('')
  const [avatar2, setAvatar2] = useState('')


  const [previewSource, setPreviewSource] = useState('');
  const [previewSource2, setPreviewSource2] = useState('');

  const [page, setPage] = useState('')
  // const location = useLocation()
  // const {page}=location.state
  console.log(page);
  const createBanner=useSelector(state=>state.createBanner)
  const {success,loading}=createBanner
  const navigate=useNavigate()

  const dispatch=useDispatch()

  
  const handleFileInputChange = (e) => {
    console.log('1');
    const file = e.target.files[0];
    previewFile(file);
  
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setAvatar(reader.result);
      
  };
};

const next = (e) => {
  console.log('2');
  const file = e.target.files[0];
  previewFile2(file);

  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onloadend = () => {
   
    setAvatar2(reader.result)
};

};
const previewFile2 = (file) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onloadend = () => {
  
      setPreviewSource2(reader.result)
  };
};


const previewFile = (file) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onloadend = () => {
      setPreviewSource(reader.result);
  
  };
};

const handleSubmit=(e)=>{
  e.preventDefault()
  if(!page){
    Swal.fire({
      title: 'please select category.',
      text: "Thanks",
      type: 'success',    
      icon: 'error',        
    });
  }else if(!avatar || !avatar2){
    Swal.fire({
      title: 'please select images.',
      text: "Thanks",
      type: 'success',    
      icon: 'error',        
    });
  }else{
    dispatch(addBanner(page,avatar,avatar2))

  }
  // console.log(avatar,'hryy');
  // console.log(avatar2,'ho');
}

// console.log(page);
useEffect(()=>{
  if(success){ 
    Swal.fire({
      title: 'banner created Succesfully.',
      text: "Thanks",
      type: 'success',    
      icon: 'success',        
    }); 
    navigate('/banner')
  }

},[success])


  return (
    <div className="add-banner">
      <AdminNavbar>
      <Box as="section" className="addBanner">
        <Grid
          templateRows="repeat(1, 1fr)"
          templateColumns="repeat(3, 1fr)"
          className={styles.details}
          gap={4}
        >
          <GridItem
            colSpan={{ base: 4, xl: 4 }}
            className={styles.recentOrders}
          >
            <FormControl isRequired>
              <FormLabel>Select  Images for Desktop Banner</FormLabel>
              <Select placeholder="Select option" onChange={(e)=>setPage(e.target.value)}>
                <option value="home">Home</option>
                <option value="contact">Contact Us</option>
                <option value="faq">FAQ</option>
                <option value="about">About Us</option>
                <option value="review">Guest Reviews</option>
                <option value="gallery">Gallery</option>
                <option value="News & Events">News & Events</option>
                <option value="accommodation">Accommodation</option>
                <option value="Meeting & Events">Meet & Greet</option>
                <option value="lounges">Lounges</option>
                <option value="Food & Beverage">Food & Beverage</option>
                <option value="Spa & Wellness">Spa & Wellness</option>
              </Select>
  
            </FormControl>
            <form className="writeForm" onSubmit={handleSubmit}>
            <div className="">
              <div className="write">
                {previewSource && (
                  <img
                    className="writeImg"
                    src={previewSource}
                    alt="chosen"
                    // style={{ height: '300px' }}
                  />
                )}
              
                  <div className="writeFormGroup">
                    <label htmlFor="fileInput2">
                      <i
                        style={{ marginTop: "24px" }}
                        className="writeIcon fas fa-plus"
                      ></i>
                         <span>Choose For desktop</span>  
                      
                    </label>
                    <input
                      type="file"
                      id="fileInput2"
                      style={{ display: "none" }}
                      onChange={handleFileInputChange}
                    />
                  </div>
                  {/* {previewSource && (
                    <button style={{marginBottom:'40px'}} className="writeSubmit" type="submit">
                      Publish
                    </button>
                  )} */}
                
              </div>
            </div>


            <div className="addSlider">
              <div className="write">
                {previewSource2 && (
                  <img
                    className="writeImg"
                    src={previewSource2}
                    alt="chosen"
                    // style={{ height: '300px' }}
                  />
                )}
             
                  <div className="writeFormGroup">
                    <label htmlFor="fileInput">
                      <i
                        style={{ marginTop: "24px" }}
                        className="writeIcon fas fa-plus"
                      ></i>
                     <span>choose for mobile</span>  
                    </label>
                    <input
                      type="file"
                      id="fileInput"
                      style={{ display: "none" }}
                      // onChange={(e) => setFile(e.target.files[0])}
                      onChange={next}
                    />
                  </div>
                  {/* {previewSource2 && previewSource && ( */}
                    <button className="writeSubmit" type="submit">
                      Publish
                    </button>
                    {loading && <LoadingBox></LoadingBox>}
                  {/* )} */}
               
              </div>
              
            </div>
            </form>
          </GridItem>
        </Grid>
      </Box>
      </AdminNavbar>
    </div>
  );
}

export default AddBanner;
