import React, { useEffect } from 'react'
import {
  Box,
  Heading,
  SimpleGrid,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableContainer,
  GridItem,
  Grid,
} from "@chakra-ui/react";
import styles from "../styles/Dashboard.module.css";
import { Link } from "react-router-dom";
import { DELETE_FAQ_RESET, FAQ_CREATE_RESET } from '../constants/Faq';
import { useDispatch, useSelector } from 'react-redux';
import { deleteFaq, getFaq } from '../actions/FaqAction';
import Swal from 'sweetalert2';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import AdminNavbar from '../components/AdminNavbar';

function FaqScreen() {
  const createFaq=useSelector(state=>state.createFaq)
  const {success}=createFaq
  const getFaqs=useSelector(state=>state.getFaq)
  const {loading,error,faqs}=getFaqs
  const deleteFaqs=useSelector(state=>state.deleteFaq)
  const {success:successDelete,error:errorDelete}=deleteFaqs
  const dispatch=useDispatch()
  useEffect(()=>{
    if(success){
      dispatch({type:FAQ_CREATE_RESET})
     
    }
    if(successDelete){
      dispatch({type:DELETE_FAQ_RESET})
      Swal.fire({
        title: 'Faq deleted Succesfully.',
        text: "Thanks",
        type: 'success',    
        icon: 'success',        
      }); 
    }
    dispatch(getFaq())
  },[success,successDelete])


  const deleteHandler=(id)=>{
    dispatch(deleteFaq(id))
  }

  const truncate = (str, n) => {
    return str.length > n ? str.substr(0, n - 1) + "..." : str;
  };
  return (
    <div>
      <AdminNavbar>
      <Box as="section">
        <Grid
          templateRows="repeat(1, 1fr)"
          templateColumns="repeat(3, 1fr)"
          className={styles.details}
          gap={4}
        >
          <GridItem
            colSpan={{ base: 4, xl: 4 }}
            className={styles.recentOrders}
          >
            <Box className={styles.cardHeader}>
              <Heading>Faq</Heading>
              <Link to={"/add-faq"}>
                <a className={styles.adminBtn}>Add Faq</a>
              </Link>
            </Box>
            <TableContainer className={styles.tableContainer}>
              <Table variant="simple" size="lg">
                <Thead>
                  <Tr>
                    <Th style={{width:'20%'}}>Question</Th>
                    <Th style={{width:'40%'}}>Answer</Th>
                    <Th style={{width:'8%'}}></Th>
                  </Tr>
                </Thead>
                {loading? <LoadingBox></LoadingBox>:
                error? <MessageBox>{error}</MessageBox>:
                faqs.map((fq)=>(
                  <Tbody key={fq._id}>
                  <Tr>
                    <Td>{truncate(fq.question,25)}</Td>
                    <Td>{truncate(fq.answer,80)}</Td>
      
                    <Td>
                      <span className={styles.inProgress} style={{cursor:'pointer'}} onClick={()=>deleteHandler(fq._id)}>Delete</span>
                    </Td>
                    {/* <Td>
                      <span className={styles.inProgress}>Edit</span>
                    </Td> */}
                  </Tr>
                </Tbody>
                ))}
                
              </Table>
            </TableContainer>
          </GridItem>
        </Grid>
      </Box>
      </AdminNavbar>
    </div>
  )
}

export default FaqScreen