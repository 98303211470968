
export const GALLERY_CREATE_REQUEST ='GALLERY_CREATE_REQUEST'
export const GALLERY_CREATE_SUCCESS ='GALLERY_CREATE_SUCCESS'
export const GALLERY_CREATE_FAIL ='GALLERY_CREATE_FAIL'
export const GALLERY_CREATE_RESET ='GALLERY_CREATE_RESET'


export const FETCH_GALLERY_REQUEST ='FETCH_GALLERY_REQUEST'
export const FETCH_GALLERY_SUCCESS ='FETCH_GALLERY_SUCCESS'
export const FETCH_GALLERY_FAIL ='FETCH_GALLERY_FAIL'

export const DELETE_GALLERY_REQUEST ='DELETE_GALLERY_REQUEST'
export const DELETE_GALLERY_SUCCESS ='DELETE_GALLERY_SUCCESS'
export const DELETE_GALLERY_FAIL ='DELETE_GALLERY_FAIL'
export const DELETE_GALLERY_RESET='DELETE_GALLERY_RESET'
