export const FAQ_CREATE_REQUEST ='FAQ_CREATE_REQUEST'
export const FAQ_CREATE_SUCCESS ='FAQ_CREATE_SUCCESS'
export const FAQ_CREATE_FAIL ='FAQ_CREATE_FAIL'
export const FAQ_CREATE_RESET ='FAQ_CREATE_RESET'


export const FETCH_FAQ_REQUEST ='FETCH_FAQ_REQUEST'
export const FETCH_FAQ_SUCCESS ='FETCH_FAQ_SUCCESS'
export const FETCH_FAQ_FAIL ='FETCH_FAQ_FAIL'

export const DELETE_FAQ_REQUEST ='DELETE_FAQ_REQUEST'
export const DELETE_FAQ_SUCCESS ='DELETE_FAQ_SUCCESS'
export const DELETE_FAQ_FAIL ='DELETE_FAQ_FAIL'
export const DELETE_FAQ_RESET='DELETE_FAQ_RESET'
