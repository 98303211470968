import { Box, Grid, GridItem, Heading, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom'
import { getBanner } from '../actions/BannerAction';
import AdminNavbar from '../components/AdminNavbar';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import { BANNER_CREATE_RESET } from '../constants/Banner';
import styles from "../styles/Dashboard.module.css";


function BannerScreen() {
  const gallery='gallery'

  const createBanner=useSelector(state=>state.createBanner)
  const {success}=createBanner
  const getBanners=useSelector(state=>state.getBanner)
  const {loading,error,banners}=getBanners
  const [filterArray, setFilterArray] = useState([])
  const dispatch=useDispatch()
  const click=(page)=>{
    navigate('/add-banner',{state:{page}})
  }


  useEffect(()=>{
     if(success){
      dispatch({type:BANNER_CREATE_RESET})
     }
     dispatch(getBanner())
  },[success,dispatch])
 
  // useEffect(()=>{
  //    if(!loading && !error){
  //     const filter=banners.sort((a,b)=>a.page.localeCompare(b.page))
  //     setFilterArray(banners)
  //     console.log(filter,'fil');
  //    }
  // },[banners])
  const navigate=useNavigate()
  return (
    <div >
        <AdminNavbar>
         <Box as="section" className='banner-screen'>
        <Grid
          templateRows="repeat(1, 1fr)"
          templateColumns="repeat(3, 1fr)"
          className={styles.details}
          gap={4}
        >
          <GridItem
            colSpan={{ base: 4, xl: 4 }}
            className={styles.recentOrders}
          >
            <Box className={styles.cardHeader}>
              <Heading>Banner Screen</Heading>
              <Link to={"/add-banner"}>
                <a className={styles.adminBtn}>Add/Edit Banner</a>
              </Link>
              {/* <Link to={"/add-banners"}>
                <a className={styles.adminBtn}>Add/Edit Banner For Mobile</a>
              </Link> */}
            </Box>
            <TableContainer className={styles.tableContainer}>
              <Table variant="simple" size="lg">
                <Thead>
                  <Tr>
                    <Th style={{width:'35%'}}>Page</Th>
                    <Th style={{width:'40%'}}>Desktop</Th> 
                    <Th style={{width:'40%'}}>Mobile</Th>              

                    {/* <Th style={{width:'8%'}}></Th> */}
                  </Tr>
                </Thead>
                {loading ? <LoadingBox></LoadingBox>:
                error ? <MessageBox>{error}</MessageBox>:
                banners.map((ban)=>(
                  <Tbody>
                  <Td>{ban.page}</Td>  
                  <Td className='b-1'> <img  src={ban.largeImage} alt="" /></Td>
                  <Td className='b-2'><img src={ban.mobileImage} alt="" /></Td>
                  {/* <Td> <span  className={styles.inProgress} >Edit</span>  </Td>  */}
 
                 </Tbody>
                ))}
               


          
              </Table>
            </TableContainer>
          </GridItem>
        </Grid>
      </Box>
      </AdminNavbar>
    </div>
  )
}

export default BannerScreen