
export const REVIEW_CREATE_REQUEST ='REVIEW_CREATE_REQUEST'
export const REVIEW_CREATE_SUCCESS ='REVIEW_CREATE_SUCCESS'
export const REVIEW_CREATE_FAIL ='REVIEW_CREATE_FAIL'
export const REVIEW_CREATE_RESET ='REVIEW_CREATE_RESET'


export const FETCH_REVIEW_REQUEST ='FETCH_REVIEW_REQUEST'
export const FETCH_REVIEW_SUCCESS ='FETCH_REVIEW_SUCCESS'
export const FETCH_REVIEW_FAIL ='FETCH_REVIEW_FAIL'

export const DELETE_REVIEW_REQUEST ='DELETE_REVIEW_REQUEST'
export const DELETE_REVIEW_SUCCESS ='DELETE_REVIEW_SUCCESS'
export const DELETE_REVIEW_FAIL ='DELETE_REVIEW_FAIL'
export const DELETE_REVIEW_RESET='DELETE_REVIEW_RESET'
