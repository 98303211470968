import { Box, Grid, GridItem, Heading, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2';
import { deleteGallery, getGallery } from '../actions/GalleryAction';
import AdminNavbar from '../components/AdminNavbar';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import { DELETE_GALLERY_RESET, GALLERY_CREATE_RESET } from '../constants/Gallery';
import styles from "../styles/Dashboard.module.css";


function GalleryScreen() {
  const createGallery=useSelector(state=>state.createGallery)
  const {success}=createGallery
  const getGallerys=useSelector(state=>state.getGallery)
  const {loading,error,gallery}=getGallerys
  const deleteGallerys=useSelector(state=>state.deleteGallery)
  const {success:successDelete,error:errorDelete,loading:loadingDelete}=deleteGallerys
  const dispatch=useDispatch()
  useEffect(()=>{
    if(success){
      dispatch({type:GALLERY_CREATE_RESET})
     
    }
    if(successDelete){
      dispatch({type:DELETE_GALLERY_RESET})
      Swal.fire({
        title: 'Image deleted Succesfully.',
        text: "Thanks",
        type: 'success',    
        icon: 'success',        
      }); 
    }
    dispatch(getGallery())
  },[success,successDelete])


  const deleteHandler=(id)=>{
    dispatch(deleteGallery(id))
  }
  return (
    <div>
      <AdminNavbar>
       <Box as="section">
        <Grid
          templateRows="repeat(1, 1fr)"
          templateColumns="repeat(3, 1fr)"
          className={styles.details}
          gap={4}
        >
          <GridItem
            colSpan={{ base: 4, xl: 4 }}
            className={styles.recentOrders}
          >
            <Box className={styles.cardHeader}>
              <Heading>Gallery</Heading>
              <Link to={"/add-gallery"}>
                <a className={styles.adminBtn}>Add Images</a>
              </Link>
            </Box>
                  
            <div className="home-slider">
            {loading? <LoadingBox></LoadingBox>:
            error? <MessageBox>{error}</MessageBox>:
            gallery.map((glr)=>(  
            <div key={glr._id}>
              <img
                src={glr.imageUrl}
                alt=""
              />
              <button onClick={()=>deleteHandler(glr._id)}>delete</button>
             
            </div>     
              ))}          
            </div>
            {loadingDelete && <LoadingBox></LoadingBox>}
          </GridItem>
        </Grid>
      </Box>
      </AdminNavbar>
    </div>
  );
}

export default GalleryScreen;
