import { REVIEW_CREATE_FAIL, REVIEW_CREATE_REQUEST, REVIEW_CREATE_RESET, REVIEW_CREATE_SUCCESS, DELETE_REVIEW_FAIL, DELETE_REVIEW_REQUEST, DELETE_REVIEW_RESET, DELETE_REVIEW_SUCCESS, FETCH_REVIEW_FAIL, FETCH_REVIEW_REQUEST, FETCH_REVIEW_SUCCESS } from "../constants/Review";

export const createReviewReducer= (state= {}, action)=>{
    switch(action.type){
      case REVIEW_CREATE_REQUEST:
        return {loading:true};
      case REVIEW_CREATE_SUCCESS:
        return {loading:false,success:true, response:action.payload};
      case REVIEW_CREATE_FAIL:
        return {loading:false,error:action.payload}
      case REVIEW_CREATE_RESET:
        return {};
      default:
        return state;        
    }
  }


  export const getReviewsReducer =(state={loading:true,reviews:[]},action)=>{
    switch(action.type){
      case FETCH_REVIEW_REQUEST:
        return {loading:true}
      case FETCH_REVIEW_SUCCESS:
        return {loading:false,reviews:action.payload.data}
      case FETCH_REVIEW_FAIL:
        return {loading:false, error:action.payload}
      default:
        return state;      
    }
  }

  export const deleteReviewReducer = (state = {}, action) => {
    switch (action.type) {
      case DELETE_REVIEW_REQUEST:
        return { loading: true };
      case DELETE_REVIEW_SUCCESS:
        return { loading: false, success: true ,response:action.payload};
      case DELETE_REVIEW_FAIL:
        return { loading: false, error: action.payload };
      case DELETE_REVIEW_RESET:
        return {};
      default:
        return state;
    }
  };