import {
  Box,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Select,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { addGallery } from "../actions/GalleryAction";
import AdminNavbar from "../components/AdminNavbar";
import LoadingBox from "../components/LoadingBox";
import styles from "../styles/Dashboard.module.css";

function AddGallery() {
  const [category, setCategory] = useState('')
  const [avatar, setAvatar] = useState("");
  const [fileInputState, setFileInputState] = useState("");
  const [previewSource, setPreviewSource] = useState("");
  const [selectedFile, setSelectedFile] = useState();
  const createGallery=useSelector(state=>state.createGallery)
  const {success,loading}=createGallery
  const navigate=useNavigate()


  const dispatch = useDispatch();

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    previewFile(file);
    setSelectedFile(file);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setAvatar(reader.result);
    };
  };

  const previewFile = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setPreviewSource(reader.result);
    };
  };

  const handleSubmit = (e) => {
    e.preventDefault()
    if(!category){
      Swal.fire({
        title: 'Please select category',
        text: "Thanks",
        type: 'success',    
        icon: 'error',        
      }); 
    }else{
      dispatch(addGallery(category,avatar))

    }

  };

  useEffect(()=>{
    if(success){ 
      Swal.fire({
        title: 'Gallery created Succesfully.',
        text: "Thanks",
        type: 'success',    
        icon: 'success',        
      }); 
      navigate('/gallery')
    }
  
  },[success])
  return (
    <div>
      <AdminNavbar>
      <Box as="section">
        <Grid
          templateRows="repeat(1, 1fr)"
          templateColumns="repeat(3, 1fr)"
          className={styles.details}
          gap={4}
        >
          <GridItem
            colSpan={{ base: 4, xl: 4 }}
            className={styles.recentOrders}
          >
            <FormControl isRequired>
              <FormLabel>Select Category</FormLabel>
              <Select placeholder="Select option" onChange={(e)=>setCategory(e.target.value)}>
                <option value="Front Exterior">Front Exterior</option>
                <option value="Launch">Launch</option>
                <option value="Rooms">Rooms</option>
                <option value="Dining">Dining</option>
                <option value="Spa">Spa</option>
                <option value="Pool">Pool</option>
              </Select>
  
            </FormControl>
            <div className="addSlider">
              <div className="write">
                {previewSource && (
                  <img
                    className="writeImg"
                    src={previewSource}
                    alt="chosen"
                    // style={{ height: '300px' }}
                  />
                )}
                <form className="writeForm" onSubmit={handleSubmit}>
                  <div className="writeFormGroup">
                    <label htmlFor="fileInput">
                      <i
                        style={{ marginTop: "24px" }}
                        className="writeIcon fas fa-plus"
                      ></i>
                    </label>
                    <input
                      type="file"
                      id="fileInput"
                      style={{ display: "none" }}
                      // onChange={(e) => setFile(e.target.files[0])}
                      onChange={handleFileInputChange}
                    />
                  </div>
                  {previewSource && (
                    <button className="writeSubmit" type="submit">
                      Publish
                    </button>
                  )}
                  {loading && <LoadingBox></LoadingBox>}
                </form>
              </div>
            </div>
          </GridItem>
        </Grid>
      </Box>
      </AdminNavbar>
    </div>
  );
}

export default AddGallery;
