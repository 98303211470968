import React, { useEffect } from 'react'
import {
  Box,
  Heading,
  SimpleGrid,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableContainer,
  GridItem,
  Grid,
} from "@chakra-ui/react";
import styles from "../styles/Dashboard.module.css";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { DELETE_REVIEW_RESET, REVIEW_CREATE_RESET } from '../constants/Review';
import { deleteReview, getReview } from '../actions/ReviewAction';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import AdminNavbar from '../components/AdminNavbar';
function ReviewScreen() {

  const createReview=useSelector(state=>state.createReview)
  const {success}=createReview
  const getReviews=useSelector(state=>state.getReview)
  const {loading,error,reviews}=getReviews
  const deleteReviews=useSelector(state=>state.deleteReview)
  const {success:successDelete,error:errorDelete}=deleteReviews
  const dispatch=useDispatch()
  useEffect(()=>{
    if(success){
      dispatch({type:REVIEW_CREATE_RESET})
     
    }
    if(successDelete){
      dispatch({type:DELETE_REVIEW_RESET})
      Swal.fire({
        title: 'Review deleted Succesfully.',
        text: "Thanks",
        type: 'success',    
        icon: 'success',        
      }); 
    }
    dispatch(getReview())
  },[success,successDelete])


  const deleteHandler=(id)=>{
    dispatch(deleteReview(id))
  }

  const truncate = (str, n) => {
    return str.length > n ? str.substr(0, n - 1) + "..." : str;
  };
  return (
    <div>
      <AdminNavbar>
       <Box as="section">
        <Grid
          templateRows="repeat(1, 1fr)"
          templateColumns="repeat(3, 1fr)"
          className={styles.details}
          gap={4}
        >
          <GridItem
            colSpan={{ base: 4, xl: 4 }}
            className={styles.recentOrders}
          >
            <Box className={styles.cardHeader}>
              <Heading>Review Screen</Heading>
              <Link to={"/add-review"}>
                <a className={styles.adminBtn}>Add Review</a>
              </Link>
            </Box>
            <TableContainer className={styles.tableContainer}>
              <Table variant="simple" size="lg">
                <Thead>
                  <Tr>
                    <Th style={{width:'20%'}}>Title</Th>
                    <Th style={{width:'40%'}}>description</Th>
                    <Th style={{width:'20%'}}>name</Th>
                 
                    <Th style={{width:'8%'}}>place</Th>
                    <Th style={{width:'8%'}}></Th>
                  </Tr>
                </Thead>
                {loading ? <LoadingBox></LoadingBox>:
                error ? <MessageBox>{error}</MessageBox>:
                reviews.map((rv)=>(

                
                <Tbody>
                  <Tr>
                    <Td>{rv.title}</Td>
                    <Td>{truncate(rv.data,20)}</Td>
                    <Td>{rv.name}</Td>
                    <Td>{rv.place}</Td>

                    {/* <Td>Cash</Td> */}
                    <Td>
                      <span style={{cursor:'pointer'}} className={styles.inProgress} onClick={()=>deleteHandler(rv._id)}>Delete</span>
                    </Td>
                    {/* <Td>
                      <span className={styles.inProgress}>Edit</span>
                    </Td> */}
                  </Tr>
                </Tbody>
                ))}
              </Table>
            </TableContainer>
          </GridItem>
        </Grid>
      </Box>
      </AdminNavbar>
    </div>
  )
}

export default ReviewScreen