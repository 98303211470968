import React, { useEffect, useState } from 'react'
import {
  Box,
  Heading,
  SimpleGrid,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableContainer,
  GridItem,
  Grid,
} from "@chakra-ui/react";
import styles from "../styles/Dashboard.module.css";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { deleteBlog, getBlog } from '../actions/UploadAction';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';
import { BLOG_CREATE_RESET, DELETE_BLOG_RESET } from '../constants/Blog';
import Swal from 'sweetalert2';
import AdminNavbar from '../components/AdminNavbar';

function BlogScreen() {

  const dispatch=useDispatch()
  const getBlogs=useSelector(state=>state.getBlogs)
  const {loading,error,blogs}=getBlogs
  const deleteBlogs=useSelector(state=>state.deleteBlog)
  const {loading:loadingDelete,success:successDelete,error:errorDelete,response}=deleteBlogs
  const createBlog=useSelector(state=>state.createBlog)
    const {successCreate}=createBlog

  useEffect(()=>{
     dispatch(getBlog())
     if(successDelete){
      dispatch({type:DELETE_BLOG_RESET})
      Swal.fire({
        title: 'blog deleted Succesfully.',
        text: "Thanks",
        type: 'success',    
        icon: 'success',        
      }); 
     }
     if(successCreate){
      dispatch({type:BLOG_CREATE_RESET})
     }
  },[dispatch,successDelete,successCreate])

  if(response){
    console.log(response,'rs');
  }

  const deletehandler=(id)=>{
    dispatch(deleteBlog(id))
  }

  const truncate = (str, n) => {
    return str.length > n ? str.substr(0, n - 1) + "..." : str;
  };

  return (
    <div>
      <AdminNavbar>
      <Box as="section">
        <Grid
          templateRows="repeat(1, 1fr)"
          templateColumns="repeat(3, 1fr)"
          className={styles.details}
          gap={4}
        >
          <GridItem
            colSpan={{ base: 4, xl: 4 }}
            className={styles.recentOrders}
          >
            <Box className={styles.cardHeader}>
              <Heading>Blog Screen</Heading>
              <Link to={"/add-blog"}>
                <a className={styles.adminBtn}>Add Blog</a>
              </Link>
            </Box>
            <TableContainer className={styles.tableContainer}>
              <Table variant="simple" size="lg">
                <Thead>
                  <Tr>
                    <Th style={{width:'30%'}}>Image</Th>
                   
                    <Th style={{width:'25'}}>title</Th>
                    <Th style={{width:'36%'}}>description</Th>
                    {/* <Th style={{width:'8%'}}>date</Th> */}
                    <Th style={{width:'8%'}}></Th>
                    {/* <Th style={{width:'8%'}}></Th> */}
                  </Tr>
                </Thead>
                {loading ? <LoadingBox></LoadingBox>:
                 error ? <MessageBox>{error}</MessageBox>:
                 blogs.data.map((blg)=>(    
                <Tbody key={blg._id}>
                  <Tr>
                    <Td>
                        <img style={{height:'135px',width:'242px',objectFit:'cover',borderRadius:'4px'}} src={blg.avatar} alt="" />
                    </Td>
                   
                    <Td>{truncate(blg.title,25)}</Td>
                    <Td> {truncate(blg.data,35)}</Td>

                    <Td>
                      <span style={{cursor:'pointer'}} className={styles.inProgress} onClick={()=>deletehandler(blg._id)}>Delete</span>
                    </Td>
                  </Tr>
                </Tbody>
                ))}
              </Table>
            </TableContainer>
          </GridItem>
        </Grid>
      </Box>
      </AdminNavbar>
    </div>
  )
}

export default BlogScreen