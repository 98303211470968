import {applyMiddleware, combineReducers, compose, legacy_createStore as createStore} from 'redux'
import thunk from 'redux-thunk'
import { createBannerReducer, deleteBannerReducer, getBannerReducer } from './reducers/BannerReducer';
import { createFaqReducer, deleteFaqReducer, getFaqsReducer } from './reducers/FaqReducer';
import { createGalleryReducer, deleteGalleryReducer, getGalleryReducer } from './reducers/GalleryReducer';
import { adminSigninReducer, getFormsReducer } from './reducers/LoginReducer';
import { createReviewReducer, deleteReviewReducer, getReviewsReducer } from './reducers/ReviewReducer';
import { createSliderReducer, deleteSliderReducer, getSlidersReducer } from './reducers/SliderReducer';
import { createBlogReducer, deleteBlogReducer, getBlogsReducer } from './reducers/UploadReducers';


    const initialState = {
       
        adminSignin: {
          adminInfo: localStorage.getItem('adminInfoDih')
            ? JSON.parse(localStorage.getItem('adminInfoDih'))
            : null,
        },
      };



const reducer=combineReducers({
    createBlog:createBlogReducer,
    getBlogs:getBlogsReducer,
    deleteBlog:deleteBlogReducer,
    createBanner:createBannerReducer,
    getBanner:getBannerReducer,
    deleteBanner:deleteBannerReducer,
    createGallery:createGalleryReducer,
    getGallery:getGalleryReducer,
    deleteGallery:deleteGalleryReducer,
    createReview:createReviewReducer,
    getReview:getReviewsReducer,
    deleteReview:deleteReviewReducer,
    createFaq:createFaqReducer,
    getFaq:getFaqsReducer,
    deleteFaq:deleteFaqReducer,
    adminSignin:adminSigninReducer,
    getSlider:getSlidersReducer,
    deleteSlider:deleteSliderReducer,
    createSlider:createSliderReducer,
    getForms:getFormsReducer,
})


const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducer,initialState,composeEnhancer(applyMiddleware(thunk)));

export default store