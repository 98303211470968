import { SLIDER_CREATE_FAIL, SLIDER_CREATE_REQUEST, SLIDER_CREATE_RESET, SLIDER_CREATE_SUCCESS, DELETE_SLIDER_FAIL, DELETE_SLIDER_REQUEST, DELETE_SLIDER_RESET, DELETE_SLIDER_SUCCESS, FETCH_SLIDER_FAIL, FETCH_SLIDER_REQUEST, FETCH_SLIDER_SUCCESS } from "../constants/Slider";

export const createSliderReducer= (state= {}, action)=>{
    switch(action.type){
      case SLIDER_CREATE_REQUEST:
        return {loading:true};
      case SLIDER_CREATE_SUCCESS:
        return {loading:false,success:true, response:action.payload};
      case SLIDER_CREATE_FAIL:
        return {loading:false,error:action.payload}
      case SLIDER_CREATE_RESET:
        return {};
      default:
        return state;        
    }
  }


  export const getSlidersReducer =(state={loading:true,sliders:[]},action)=>{
    switch(action.type){
      case FETCH_SLIDER_REQUEST:
        return {loading:true}
      case FETCH_SLIDER_SUCCESS:
        return {loading:false,sliders:action.payload.data}
      case FETCH_SLIDER_FAIL:
        return {loading:false, error:action.payload}
      default:
        return state;      
    }
  }

  export const deleteSliderReducer = (state = {}, action) => {
    switch (action.type) {
      case DELETE_SLIDER_REQUEST:
        return { loading: true };
      case DELETE_SLIDER_SUCCESS:
        return { loading: false, success: true ,response:action.payload};
      case DELETE_SLIDER_FAIL:
        return { loading: false, error: action.payload };
      case DELETE_SLIDER_RESET:
        return {};
      default:
        return state;
    }
  };