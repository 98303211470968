import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomeScreen from "./screens/HomeScreen";
import BlogScreen from "./screens/BlogScreen";
import "./styles/globals.css";
import AdminNavbar from "./components/AdminNavbar";
import { ChakraProvider } from "@chakra-ui/react";
import BannerImage from "./screens/BannerImage";
import GalleryScreen from "./screens/GalleryScreen";
import AddBlog from "./screens/AddBlog";
import ReviewScreen from "./screens/ReviewScreen";
import AddReview from "./screens/AddReview";
import BannerScreen from "./screens/BannerScreen";
import AddGallery from "./screens/AddGallery";
import AddBanner from "./screens/AddBanner";
import HomeSliderScreen from "./screens/HomeSliderScreen";
import AddHomeSlider from "./screens/AddHomeSlider";
import FaqScreen from "./screens/FaqScreen";
import AddFaq from "./screens/AddFaq";
import LoginScreen from "./screens/LoginScreen";
import AdminRoute from "./components/AdminRoute";
import AddBanners from "./screens/AddBanners";
import FormScreen from "./screens/FormScreen";

function App() {
  return (
    <div className={"adminContainer"}>
      <ChakraProvider>
        <BrowserRouter>
          
            <Routes>
  
              <Route path="/" element={<LoginScreen />}/>
              <Route path="/banner-image" element={<AdminRoute><BannerImage /></AdminRoute>}> {" "}</Route> 
            
              <Route path="/blog" element={<AdminRoute><BlogScreen/></AdminRoute>}> {" "}</Route> 
              <Route path="/add-blog" element={<AdminRoute><AddBlog/></AdminRoute>}> {" "}</Route> 

              <Route path="/gallery" element={<AdminRoute><GalleryScreen/></AdminRoute>}> {" "}</Route> 
              <Route path="/add-gallery" element={<AdminRoute><AddGallery/></AdminRoute>}> {" "}</Route> 

                  
              <Route path="/review" element={<AdminRoute><ReviewScreen/></AdminRoute>}> {" "}</Route> 
              <Route path="/add-review" element={<AdminRoute><AddReview/></AdminRoute>}> {" "}</Route> 

              <Route path="/banner" element={<AdminRoute><BannerScreen/></AdminRoute>}> {" "}</Route> 
              <Route path="/add-banner" element={<AdminRoute><AddBanner/></AdminRoute>}> {" "}</Route> 
              <Route path="/add-banners" element={<AdminRoute><AddBanners/></AdminRoute>}> {" "}</Route> 

              <Route path="/homeslider" element={<AdminRoute><HomeSliderScreen/></AdminRoute>}> {" "}</Route> 
              <Route path="/add-homeslider" element={<AdminRoute><AddHomeSlider/></AdminRoute>}> {" "}</Route> 
      
              <Route path="/faq" element={<AdminRoute><FaqScreen/></AdminRoute>}> {" "}</Route> 
              <Route path="/add-faq" element={<AdminRoute><AddFaq/></AdminRoute>}> {" "}</Route> 

              <Route path="/form" element={<AdminRoute><FormScreen/></AdminRoute>}> {" "}</Route> 

            </Routes>
          {/* </AdminNavbar> */}
        </BrowserRouter>
      </ChakraProvider>
    </div>
  );
}

export default App;
