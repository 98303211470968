import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getForms } from "../actions/LoginAction";
import AdminNavbar from "../components/AdminNavbar";
import LoadingBox from "../components/LoadingBox";
import MessageBox from "../components/MessageBox";

function FormScreen() {
  const contactForm=useSelector(state=>state.getForms)
  const {loading,error,forms}=contactForm
  const dispatch=useDispatch()

  useEffect(()=>{
    dispatch(getForms())
  },[dispatch])

  if(forms){
    console.log(forms);
  }
  return (
    <div className="form">
      <AdminNavbar>
     
          
     
        <div className="form-table">
        <table>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Country</th>
            <th>Telephone</th>
            <th>City</th>
            <th>Subject</th>
            <th>Enquiry</th>
          </tr>
          {loading? <LoadingBox></LoadingBox>:
           error? <MessageBox>{error}</MessageBox>:
           forms.length > 0 ? forms.map((frm)=>(
          <tr key={frm._id}>
            <td>{frm.name}</td>
            <td>{frm.email}</td>
            <td>{frm.country}</td>
            <td>+{frm.phone}</td>
            <td>{frm.city}</td>
            <td>{frm.subject}</td>
            <td>{frm.message}</td>         
          </tr>
           )):
           (
            <MessageBox>No Details</MessageBox>
           )
           }
          
      
        </table>
        </div>
          
      </AdminNavbar>
    </div>
  );
}

export default FormScreen;
