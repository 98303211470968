import { BLOG_CREATE_FAIL, BLOG_CREATE_REQUEST, BLOG_CREATE_RESET, BLOG_CREATE_SUCCESS, DELETE_BLOG_FAIL, DELETE_BLOG_REQUEST, DELETE_BLOG_RESET, DELETE_BLOG_SUCCESS, FETCH_BLOG_FAIL, FETCH_BLOG_REQUEST, FETCH_BLOG_SUCCESS } from "../constants/Blog";

export const createBlogReducer= (state= {}, action)=>{
    switch(action.type){
      case BLOG_CREATE_REQUEST:
        return {loading:true};
      case BLOG_CREATE_SUCCESS:
        return {loading:false,success:true, response:action.payload};
      case BLOG_CREATE_FAIL:
        return {loading:false,error:action.payload}
      case BLOG_CREATE_RESET:
        return {};
      default:
        return state;        
    }
  }


  export const getBlogsReducer =(state={loading:true,blogs:[]},action)=>{
    switch(action.type){
      case FETCH_BLOG_REQUEST:
        return {loading:true}
      case FETCH_BLOG_SUCCESS:
        return {loading:false,blogs:action.payload}
      case FETCH_BLOG_FAIL:
        return {loading:false, error:action.payload}
      default:
        return state;      
    }
  }

  export const deleteBlogReducer = (state = {}, action) => {
    switch (action.type) {
      case DELETE_BLOG_REQUEST:
        return { loading: true };
      case DELETE_BLOG_SUCCESS:
        return { loading: false, success: true ,response:action.payload};
      case DELETE_BLOG_FAIL:
        return { loading: false, error: action.payload };
      case DELETE_BLOG_RESET:
        return {};
      default:
        return state;
    }
  };