import { BANNER_CREATE_FAIL, BANNER_CREATE_REQUEST, BANNER_CREATE_RESET, BANNER_CREATE_SUCCESS, DELETE_BANNER_FAIL, DELETE_BANNER_REQUEST, DELETE_BANNER_RESET, DELETE_BANNER_SUCCESS, FETCH_BANNER_FAIL, FETCH_BANNER_REQUEST, FETCH_BANNER_SUCCESS } from "../constants/Banner";

export const createBannerReducer= (state= {}, action)=>{
    switch(action.type){
      case BANNER_CREATE_REQUEST:
        return {loading:true};
      case BANNER_CREATE_SUCCESS:
        return {loading:false,success:true, response:action.payload};
      case BANNER_CREATE_FAIL:
        return {loading:false,error:action.payload}
      case BANNER_CREATE_RESET:
        return {};
      default:
        return state;        
    }
  }


  export const getBannerReducer =(state={loading:true,banners:[]},action)=>{
    switch(action.type){
      case FETCH_BANNER_REQUEST:
        return {loading:true}
      case FETCH_BANNER_SUCCESS:
        return {loading:false,banners:action.payload.data}
      case FETCH_BANNER_FAIL:
        return {loading:false, error:action.payload}
      default:
        return state;      
    }
  }

  export const deleteBannerReducer = (state = {}, action) => {
    switch (action.type) {
      case DELETE_BANNER_REQUEST:
        return { loading: true };
      case DELETE_BANNER_SUCCESS:
        return { loading: false, success: true ,response:action.payload};
      case DELETE_BANNER_FAIL:
        return { loading: false, error: action.payload };
      case DELETE_BANNER_RESET:
        return {};
      default:
        return state;
    }
  };