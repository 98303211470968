import axios from "axios";
import { BLOG_CREATE_FAIL, BLOG_CREATE_REQUEST, BLOG_CREATE_SUCCESS, DELETE_BLOG_FAIL, DELETE_BLOG_REQUEST, DELETE_BLOG_SUCCESS, FETCH_BLOG_FAIL, FETCH_BLOG_REQUEST, FETCH_BLOG_SUCCESS, Url } from "../constants/Blog";

export const addBlog =(title,datas,avatar)=>async(dispatch)=>{
    dispatch({type:BLOG_CREATE_REQUEST});
    try{
      const {data}= await axios.post(`${Url}/api/v1/blog`,{title,data:datas,avatar},{
        headers:{'Content-Type': 'application/json'}  
      })
      dispatch({type:BLOG_CREATE_SUCCESS,payload:data})
    }catch(error){
      const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
       dispatch({ type: BLOG_CREATE_FAIL, payload: message })
    }
  }

  export const getBlog =()=>async(dispatch)=>{
    dispatch({type:FETCH_BLOG_REQUEST});
    try{
      const {data}= await axios.get(`${Url}/api/v1/blog`)
      dispatch({type:FETCH_BLOG_SUCCESS,payload:data})
    }catch(error){
      const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
       dispatch({ type: FETCH_BLOG_FAIL, payload: message })
    }
  }

  export const deleteBlog =(id)=>async(dispatch)=>{
    dispatch({type:DELETE_BLOG_REQUEST});
    try{
      const {data}= await axios.delete(`${Url}/api/v1/blog/${id}`)
      dispatch({type:DELETE_BLOG_SUCCESS,payload:data})
    }catch(error){
      const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
       dispatch({ type: DELETE_BLOG_FAIL, payload: message })
    }
  }