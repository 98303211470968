import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { addBlog } from '../actions/UploadAction';
import AdminNavbar from '../components/AdminNavbar';
import LoadingBox from '../components/LoadingBox';
import { BLOG_CREATE_RESET, Url } from '../constants/Blog';


function AddBlog() {

    const [title, setTitle] = useState('')
    const [datas, setDatas] = useState('')
    const [avatar, setAvatar] = useState('')
    const [fileInputState, setFileInputState] = useState('');
    const [previewSource, setPreviewSource] = useState('');
    const [selectedFile, setSelectedFile] = useState();
    const dispatch=useDispatch()

    const createBlog=useSelector(state=>state.createBlog)
    const {success,loading}=createBlog
    const navigate=useNavigate()


    const handleFileInputChange = (e) => {
      const file = e.target.files[0];
      previewFile(file);
      setSelectedFile(file);
      setFileInputState(e.target.value);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setAvatar(reader.result);
    };
  };

  const previewFile = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
        setPreviewSource(reader.result);
    };
};

useEffect(()=>{
  if(success){
    
    Swal.fire({
      title: 'blog created Succesfully.',
      text: "Thanks",
      type: 'success',    
      icon: 'success',        
    }); 
    navigate('/blog')
  }

},[success])


const handleSubmit=async(e)=>{
  e.preventDefault()
// if (!selectedFile) return;
  // console.log(avatar,'avt');
  if(!title){
    console.log('no title');
    Swal.fire({
      title: 'please type title.',
      text: "Thanks",
      type: 'success',    
      icon: 'error',        
    }); 
  }else if(!datas){
    console.log('no titles');

    Swal.fire({
      title: 'please write about blog',
      text: "Thanks",
      type: 'success',    
      icon: 'error',        
    }); 
  }else if(!avatar){
    console.log('no titleaaa');

    Swal.fire({
      title: 'please select image.',
      text: "Thanks",
      type: 'success',    
      icon: 'error',        
    }); 
  }else{
    console.log('no title');

    dispatch(addBlog(title,datas,avatar))

  }
}

  return (
    <div>
      <AdminNavbar>
        <div className="write">
        {previewSource && (
                <img
                     className="writeImg"
                    src={previewSource}
                    alt="chosen"
                    // style={{ height: '300px' }}
                />
            )}
      <form className="writeForm" onSubmit={handleSubmit}>
        <div className="writeFormGroup">
          <label htmlFor="fileInput">
            <i className="writeIcon fas fa-plus"></i>
          </label>
          <input
            type="file"
            id="fileInput"
            style={{ display: "none" }}
            // onChange={(e) => setFile(e.target.files[0])}
            onChange={handleFileInputChange}
          />
          <input
            className="writeInput"
            placeholder="Title"
            type="text"
            autoFocus={true}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        <div className="writeFormGroup">
          <textarea
            className="writeInput writeText"
            placeholder="Tell your story..."
            type="text"
            autoFocus={true}
            onChange={(e) => setDatas(e.target.value)}
          />
        </div>
        <button className="writeSubmit" type="submit">
          Publish
        </button>
        {loading && <LoadingBox></LoadingBox>}
      </form>
    </div>
    </AdminNavbar>
    </div>
  )
}

export default AddBlog