import axios from "axios";
import { Url } from "../constants/Blog";
import { REVIEW_CREATE_FAIL, REVIEW_CREATE_REQUEST, REVIEW_CREATE_SUCCESS, DELETE_REVIEW_FAIL, DELETE_REVIEW_REQUEST, DELETE_REVIEW_SUCCESS, FETCH_REVIEW_FAIL, FETCH_REVIEW_REQUEST, FETCH_REVIEW_SUCCESS} from "../constants/Review";

export const addReview =(title,name,place,review)=>async(dispatch)=>{
    dispatch({type:REVIEW_CREATE_REQUEST});
    try{
      const {data}= await axios.post(`${Url}/api/v1/review`,{title,name,place,data:review},{
        headers:{'Content-Type': 'application/json'}  
      })
      dispatch({type:REVIEW_CREATE_SUCCESS,payload:data})
    }catch(error){
      const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
       dispatch({ type: REVIEW_CREATE_FAIL, payload: message })
    }
  }

  export const getReview =()=>async(dispatch)=>{
    dispatch({type:FETCH_REVIEW_REQUEST});
    try{
      const {data}= await axios.get(`${Url}/api/v1/review`)
      dispatch({type:FETCH_REVIEW_SUCCESS,payload:data})
    }catch(error){
      const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
       dispatch({ type: FETCH_REVIEW_FAIL, payload: message })
    }
  }

  export const deleteReview =(id)=>async(dispatch)=>{
    dispatch({type:DELETE_REVIEW_REQUEST});
    try{
      const {data}= await axios.delete(`${Url}/api/v1/review/${id}`)
      dispatch({type:DELETE_REVIEW_SUCCESS,payload:data})
    }catch(error){
      const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
       dispatch({ type: DELETE_REVIEW_FAIL, payload: message })
    }
  }