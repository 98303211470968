export const BLOG_CREATE_REQUEST = "BLOG_CREATE_REQUEST";
export const BLOG_CREATE_SUCCESS = "BLOG_CREATE_SUCCESS";
export const BLOG_CREATE_FAIL = "BLOG_CREATE_FAIL";
export const BLOG_CREATE_RESET = "BLOG_CREATE_RESET";

export const FETCH_BLOG_REQUEST = "FETCH_BLOG_REQUEST";
export const FETCH_BLOG_SUCCESS = "FETCH_BLOG_SUCCESS";
export const FETCH_BLOG_FAIL = "FETCH_BLOG_FAIL";

export const DELETE_BLOG_REQUEST = "DELETE_BLOG_REQUEST";
export const DELETE_BLOG_SUCCESS = "DELETE_BLOG_SUCCESS";
export const DELETE_BLOG_FAIL = "DELETE_BLOG_FAIL";
export const DELETE_BLOG_RESET = "DELETE_BLOG_RESET";

// export const Url='https://dih-backend.herokuapp.com'
// export const Url='https://dih-backend.up.railway.app'
export const Url = "https://dih-node-vercel-production-3413.up.railway.app";
